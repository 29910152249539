/**
 * 多数のコンポーネント、moduleで共通して使用することを
 * 一か所で管理するためのJSファイル
 */

/**
 * 指定秒数待機するPromiseを作成します
 * @param {number} secs スクロール時間のミリ秒です。
 * @returns {Promise}
 */
 export function sleep(secs) {
    return new Promise(function(resolve){
        setTimeout(() => resolve(), secs)
    })
}

/**
 * 値が10未満の場合、値に"0"を追加し2桁にする
 * @param {number} value
 * @returns {String}
 */
export function toTwoDigits(value) {
  return (value < 10 ? '0' : '') + value
}

/**
 * 現時刻のタイムスタンプを取得する
 * @returns {String}
 */
export function getTimestamp() {
  const date = new Date()
  const [month, day, year]       = [date.getMonth() + 1, date.getDate(), date.getFullYear()]
  const [hour, minutes, seconds] = [date.getHours(), date.getMinutes(), date.getSeconds()]
  return year + toTwoDigits(month) + toTwoDigits(day) + toTwoDigits(hour) + toTwoDigits(minutes) + toTwoDigits(seconds)
}

//ナビゲーション
export const tabs = [
  { id: 0, name: "建て方選択", route: '/household-type' },
  { id: 1, name: "報告方法選択", route: '/report-type' },
  { id: 2, name: "ファイルアップロード", route: '/file-upload' },
  { id: 3, name: "ファイルチェック", route: '/file-check' },
]
